import React from 'react'
import LayoutCard from '../layouts/LayoutCard.js'
import { UserDataProvider } from '../context/UserDataContext.js'

const IndexPage = () => {
  return (
    <>
      <UserDataProvider>
        <LayoutCard />
      </UserDataProvider>
    </>
  )
}

export default IndexPage
