import React from 'react'
import './footer.scss'

const Footer = () => {
  const currentTime = new Date()
  const year = currentTime.getFullYear()
  return (
    <footer className="gd-Footer">
      <div className="gd-Footer-copyright">
        <p>© Copyright 2010 - {year} Gavula Design</p>
        <p>Homemade in Hopkinton, MA</p>
      </div>
    </footer>
  )
}

export default Footer
