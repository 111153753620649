import React from 'react'
import '../icon.scss'

const IconFlip = () => (
  <svg className="Icon IconFlip" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="M25.4,10.23l2.54-1.08l-2.28,7.43l-7.43-3.3l2.36-1.01c0,0-1.83-3.56-4.79-5.51S9.9,3.63,6.05,4.52
	s-6.09,5.11-5.91,9.1C-0.39,9.6,0.41,4.81,5.23,2.21s10.32-0.35,12.74,1S23.05,6.69,25.4,10.23z M14.03,28.78
	c2.42,1.36,7.92,3.61,12.74,1s5.62-7.39,5.08-11.41c0.18,3.99-2.07,8.22-5.91,9.1s-6.8-0.3-9.75-2.25s-4.79-5.51-4.79-5.51
	l2.36-1.01l-7.43-3.3l-2.28,7.43l2.54-1.08C8.95,25.31,11.61,27.43,14.03,28.78z"
    />
  </svg>
)

export default IconFlip
