import React from 'react'
import { useMount, useDeepCompareEffect } from 'react-use'
// import PropTypes from "prop-types"
import Header from '../components/Header/Header.js'
import Main from '../components/Main/Main.js'
import TextPipe from '../components/TextPipe/TextPipe.js'
import PrintBanner from '../components/PrintBanner/PrintBanner.js'
import PrintableFile from '../components/downloads/GavulaXMas--2020.pdf'
import { get } from '../utilities/get.js'
import { UserDataContext } from '../context/UserDataContext.js'

import '../assets/foundation.scss'
import './layoutCard.scss'
import Footer from '../components/Footer/Footer'

const LayoutCard = () => {
  // eslint-disable-next-line no-unused-vars
  const [userData, updateUserData] = React.useContext(UserDataContext)

  useMount(() => {
    if (typeof window !== 'undefined') {
      // Collect User ID from URL parameter.
      const urlString = window.location.href
      const url = new URL(urlString)
      const params = url.searchParams.get('u')

      updateUserData({
        id: params,
      })
    }
  })

  /* React.useEffect(() => {
    // get data from GitHub api
    // fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
    fetch(`http://christmas-wpgraphql-2001.gd:8888/wp-json/wp/v2/greeting_card/17`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setStarsCount(resultData.stargazers_count)
        console.log(resultData)
      }) // set data for the number of stars
  }, [])*/

  useDeepCompareEffect(() => {
    if (userData.id !== undefined) {
      get(`static/log/LogEvent.php?u=${userData.id}&e=open`)
    }
  }, [userData])

  // Query by ID
  // https://www.wpgraphql.com/docs/posts-and-pages/

  //hrgh848h4toi59gjegoigjhr

  return (
    <>
      <Header>
        The Gavula Family <TextPipe /> 2020
      </Header>
      <Main />
      <PrintBanner file={PrintableFile} />
      <Footer />
    </>
  )
}

LayoutCard.propTypes = {
  // children: PropTypes.node,
}

export default LayoutCard
