export const get = url => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()
    req.open('GET', url)
    req.onload = () => (req.status === 200 ? resolve(req.response) : reject(Error(req.statusText)))
    req.onerror = e => reject(Error(`Network Error: ${e}`))
    req.send()
  })
}
