import React from 'react'
import { useMount } from 'react-use'
import classNames from 'classnames'
import PostCardBack from '../PostCardBack/PostCardBack.js'
import PostCardFront from '../PostCardFront/PostCardFront.js'
import ButtonIcon from '../ButtonIcon/ButtonIcon.js'
import IconFlip from '../Icon/IconFlip/IconFlip.js'
import { get } from '../../utilities/get.js'
import { UserDataContext } from '../../context/UserDataContext.js'
import './postCard.scss'

const PostCard = () => {
  const [userData] = React.useContext(UserDataContext)
  const [isFlipped, setFlipped] = React.useState(true)

  const handleHover = () => {
    setFlipped(!isFlipped)
  }
  useMount(() => {
    setFlipped(!isFlipped)
  })
  return (
    <div className="PostCard">
      <ButtonIcon
        onClick={() => {
          handleHover()
          get(`static/log/LogEvent.php?u=${userData.id}&e=flip-card`)
        }}
      >
        <IconFlip />
      </ButtonIcon>

      <div
        className={classNames('PostCard-target', {
          'is-flipped': isFlipped === true,
        })}
      />
      <div className="PostCard-flipper">
        <PostCardFront />
        <PostCardBack />
      </div>
    </div>
  )
}

export default PostCard
