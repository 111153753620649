import React from 'react'
import PropTypes from 'prop-types'

const UserDataContext = React.createContext([])

const initialData = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  message: undefined,
}

const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = React.useState(initialData)
  const updateUserData = newData => {
    setUserData(prevData => ({
      ...prevData,
      ...newData,
    }))
  }
  return <UserDataContext.Provider value={[userData, updateUserData]}>{children}</UserDataContext.Provider>
}
UserDataProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
export { UserDataContext, UserDataProvider }
