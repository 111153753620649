import React from 'react'
import '../icon.scss'

const IconDownload = () => (
  <svg className="Icon IconDownload is-16" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path className="a" d="M12,10.35V2.86L9.14,0H4V10.35H6.84V13H5l3,3,3-3H9.16V10.35Zm-7-1V1H8.38V3.61H11V9.35Z" />
  </svg>
)

export default IconDownload
