import React from 'react'
import debounce from 'lodash/debounce'
import classNames from 'classnames'
import PostCard from '../PostCard/PostCard.js'
//import { get } from '../../utilities/get.js';
//import { UserDataContext } from '../../context/UserDataContext.js'
import './main.scss'

const Main = () => {
  //const [userData] = React.useContext(UserDataContext)
  //
  const [isMessageReveled, setMessageReveled] = React.useState(false)
  //TODO: Add logic reporting full scroll event
  //const [isBottomReached, setBottomReached] = React.useState(false)
  const message = React.useRef()

  //TODO: This works, if the ID is not dynamic. So maybe it need to be async with a loader in the future.
  //TODO: We should also abstract the first segment f the URL so it work locally and on the remote server with editing.
  /*  React.useEffect(() => {
    // get data from GitHub api
    // fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
    fetch(`http://christmas-2001.gd:8888/static/log/FirstName.php?u=${userData.id}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setStarsCount(resultData.stargazers_count)
        console.log(resultData)
      }) // set data for the number of stars
  }, [])*/

  React.useLayoutEffect(() => {
    const calcMessageReveal = () => {
      const { pageYOffset } = window
      const pageInnerHeight = window.innerHeight
      const messageYOffset = message.current.getBoundingClientRect().top
      // console.log('window.pageYOffset: ' + window.pageYOffset)
      // console.log('window.innerHeight: ' + window.innerHeight)

      if (messageYOffset < pageYOffset + pageInnerHeight) {
        setMessageReveled(true)
      } else {
        setMessageReveled(false)
      }
    }

    const scrollEventDebouncer = debounce(() => {
      calcMessageReveal()
    }, 100)

    if (typeof window !== 'undefined') {
      window.addEventListener('load', calcMessageReveal())
      window.addEventListener('scroll', scrollEventDebouncer)
      window.addEventListener('resize', scrollEventDebouncer)
    }
    // Remove listener (like componentWillUnmount)
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('load', calcMessageReveal())
        window.removeEventListener('scroll', scrollEventDebouncer)
        window.removeEventListener('resize', scrollEventDebouncer)
      }
    }
  }, [])

  return (
    <main className="Main">
      <div className="Main-postCard">
        <div className="Main-postCardInner">
          <PostCard />
        </div>
      </div>
      <div className="Main-message">
        <div
          ref={message}
          className={classNames('Main-messageInner', {
            'is-visible': isMessageReveled === true,
          })}
        >
          <p>Greetings! 👋</p>
          <p>
            I was tempted to make this card into a big gag about how fantastic 2020 was. How John got in rockin&apos;
            shape at the gym or how Jenna rescued Koalas from the Australian fires. Don&apos;t forget about our trip to
            Florence! How about the girls using their elementary school&apos;s Model UN to bring peace to the Middle
            East. And good old Tom Brady is on his way to another playoff run with the Pats! We did it!
            Achievement&apos;s unlocked all around! 🙌
          </p>
          <p>
            Instead I thought I&apos;d set the jokes aside to say we&apos;ve been lucky as hell! As tough as 2020 has
            been for so many people, our little household has been largely spared, and we are grateful!
          </p>
          <p>First, we remained healthy.</p>
          <p>
            In fact the only perr-son (pun!) at our address to get seriously ill this year was our new cat, Finn 😸. A
            flame-point Siamese rescue from Mississippi who caught every illness except Covid between here and the
            Bayou. After a brief hospitalization and weeks of medicine we&apos;re happy to report he is thriving and
            adorable. His loyalty to Jenna is exceeded only by his desire for another helping of food.
          </p>
          <p>
            Cora and Lily might be a little jealous of their mom&apos;s bond with Finny. This year they turned 8 and 6
            respectively, during the height of lock-down, no less. Although Zoom birthday parties, limited social
            bubbles, remote learning, loss of summer camps and trick-or-treating have been disappointing, again
            we&apos;re lucky. After all, Cora and Lily have had each other, a back-yard (for sledding! 🛷), their own
            bedrooms and an amazing community of neighbors. Thank goodness we moved to a proper house last year!
          </p>
          <p>
            Speaking of moves, Jenna parted with Bose in June to pursue freelance design opportunities with 🌿
            eco-friendly clientele. Check out her{' '}
            <a href="https://www.jennagavula.com/" target="_blank" rel="noreferrer">
              fresh website
            </a>{' '}
            and sustainable living podcast, &quot;
            <a href="https://www.intheneww.com/" target="_blank" rel="noreferrer">
              In the NEWW
            </a>
            &quot; which stands for New England Without Waste.
          </p>
          <p>
            John could use a little less waist as well. He misses his volleyball league 🏐, but tries to burn calories
            doing work around the yard. As for business, he’s grateful to remain under contract with Liberty Mutual.
            This year he transitioned to a hybrid User experience design/ front-end dev roll, which has been great.
          </p>
          <p>All that said, we hope you and yours have faired at least as well as us. Stay safe and Happy Holidays!</p>
          <p>- John, Jenna, Cora, Lily and Finn 🐾</p>
        </div>
      </div>
    </main>
  )
}

export default Main
