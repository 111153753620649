import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './textPipe.scss'

const TextPipe = ({ children, className, padding }) => {
  return <span className={classNames('gd-TextPipe', `gd-TextPipe--${padding}`, className)}>{children}</span>
}

TextPipe.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.oneOf(['1', '2', '3', '5', '8', '13', '21']),
}

TextPipe.defaultProps = {
  children: '|',
  className: undefined,
  padding: '8',
}

export default TextPipe
