import React from 'react'
import PropTypes from 'prop-types'
import IconDownload from '../Icon/IconDownload/IconDownload.js'
import { get } from '../../utilities/get.js'
import { UserDataContext } from '../../context/UserDataContext.js'
import './printBanner.scss'

const PrintBanner = props => {
  const { file } = props
  const [userData] = React.useContext(UserDataContext)
  return (
    <section className="PrintBanner">
      <div className="PrintBanner-inner">
        <a
          href={file}
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => {
            get(`static/log/LogEvent.php?u=${userData.id}&e=print`)
          }}
        >
          <div className="PrintBanner-buttonInner">
            Download a printable PDF <IconDownload />
          </div>
        </a>
      </div>
    </section>
  )
}

PrintBanner.propTypes = {
  file: PropTypes.string,
}

PrintBanner.defaultProps = {
  file: undefined,
}

export default PrintBanner
