import React from 'react'
import PropTypes from 'prop-types'
import './header.scss'

const Header = ({ children }) => {
  return (
    <header className="Header">
      <div className="Header-inner">{children}</div>
    </header>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Header
